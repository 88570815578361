<template>
    <div class="center-user-box">
        <img :src="require('@/assets/images/lensman/fa4.jpg')" alt="">
        <div class="center-user-content">
            <div class="container">
                <!-- 头像 -->
                <el-image
                    :src="basic_data.head"
                    fit="cover"
                    class="center-user-avatar">
                    <!-- 加载中样式 -->
                    <template v-slot:placeholder>
                        <div class="image-slot">
                            <i class="el-icon-loading"></i>
                        </div>
                    </template>
                    <!-- 加载失败样式 -->
                    <template v-slot:error>
                        <div class="image-slot">
                            <i class="el-icon-s-custom"></i>
                        </div>
                    </template>
                </el-image>
                <!-- 按钮 -->
                <div class="center-user-btn-box">
                    <a href="javascript:;"
                        @click="handleDialog('centerUserDeposit')"
                        class="center-user-btn">
                        保证金（¥{{basic_data.deposit}}）
                    </a>
                    <a href="javascript:;"
                        @click="handleDialog('centerUserBalance')"
                        class="center-user-btn">
                        余额（¥{{basic_data.balance}}）
                    </a>
                </div>
                <!-- 欢迎 -->
                <div class="center-user-welcome">
                    <!-- 昵称 -->
                    <span :class="['center-user-welcome-nickname',
                    {
                        'center-user-welcome-nickname-man':basic_data.sex == 1
                    }]">{{basic_data.nickname}}</span>
                    <!-- 欢迎词 -->
                    <span class="center-user-welcome-content">
                        {{basic_data.nickname}}
                        您好，欢迎来到吉享空间
                    </span>
                </div>
            </div>
        </div>
        <el-dialog
            :visible="outsideDialog"
            @close="changeOutsideDialog(false)"
            width="30%">
            <component :is="componentId"></component>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    data() {
        return {
            componentId: ""
        }
    },
    computed: mapState({
        outsideDialog: state => state.outsideDialog,
        user_type: state => state.user.user_type,
        basic_data: state => state.user.basic_data
    }),
    components: {
        centerUserBalance: () => import("@/components/centerUserBalance"),
        centerUserDeposit: () => import("@/components/centerUserDeposit")
    },
    methods: {
        ...mapMutations([
            "changeOutsideDialog"
        ]),
        handleDialog(val){
            let isDeposit = val == 'centerUserDeposit',
                deposit = this.basic_data.deposit;
            if(isDeposit && deposit == 1000) return;
            this.changeOutsideDialog(true);
            this.componentId = val;
        },
    },
}
</script>

<style scoped>
.center-user-box{
    position: relative;
    height: 200px;
}
/* 图片 */
.center-user-box>img{
    width: 100%;
    height: 100%;
    object-fit:cover;
}
/* 内容 */
.center-user-content{
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
}
/* 头像 */
.center-user-avatar{
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
/* 按钮 */
.center-user-btn-box{
    display: inline-flex;
    vertical-align: middle;
    flex-flow: column;
    justify-content: space-around;
    height: 100px;
    margin-left: 20px;
}
.center-user-btn{
    text-align: center;
    padding: 5px 50px;
    background-color: #8d9afc;
    border-radius: 20px;
    font-size: 17px;
    color: #fff;
}
/* 欢迎 */
.center-user-welcome{
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 20px;
}
.center-user-welcome-nickname{
    color: #303133;
    padding-right: 20px;
    background-image: url("../assets/images/lensman/@1x/icon_girl.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 16px 16px;
}
.center-user-welcome-nickname-man{
    background-image: url("../assets/images/lensman/@1x/icon_male.png");
}
.center-user-welcome-content{
    display: inline-block;
    margin-left: 40px;
    color: #909399;
}
</style>